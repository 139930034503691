<template lang="pug">
  div
    template(v-if="!loading")
      v-card.mb-2(
        v-for="(item, index) in timetables"
        :key="index")
        .list-1
          .list-1__wrapper
            .list-1__title {{ item.name }}
            .list-1__settings(v-if="item.status === 'wait'")
              v-btn(
                icon
                @click="openEdit(item)"
                :permission="permissions.read_timetable")
                font-awesome-icon(
                  size="sm"
                  icon="cog")
          .list-1__item
            .list-1__item-label {{ 'base.weight' | translate }} #[br] {{ 'base.plan_fact' | translate }}
            .list-1__item-value {{ item.plan_weight }} / {{ item.fact_weight }}
          .list-1__item
            .list-1__item-label {{ 'base.silage' | translate }}
            .list-1__item-value {{ item.silages_list | liststr('number') }}
          .list-1__item
            .list-1__item-label {{ 'base.status' | translate }}
            .list-1__item-value
              span(:style="{ color: statuses[item.status].color }")
                | {{ statuses[item.status].name | translate }}

      v-table-body(
        v-if="!loading"
        outside-table)

        planning-table-empty(
          v-if="!timetables.length")

    template(v-else)
      v-card.mb-2(v-for="i in 5" :key="i")
        .list-1
          .list-1__title
            .list-1__title-skeleton
          .list-1__item(v-for="j in 3" :key="`${i}-${j}`")
            .list-1__item-skeleton

    v-dialog(
      max-width="320"
      v-model="editModal.show")
      edit-planning-modal(
        :item="editModal.item"
        @close="editModal.show = false")

</template>

<script>
import { mapGetters } from 'vuex'
import { PLANNING_STATUSES } from '@/util/consts.js'

import PlanningTableEmpty from './PlanningTableEmpty'
import EditPlanningModal from './modals/EditPlanningModal'
import permissions from '@/util/permissions'

export default {
  name: 'PlanningTableMobile',

  components: {
    PlanningTableEmpty,
    EditPlanningModal
  },

  props: {
    loading: {
      type: Boolean,
      required: true
    }
  },

  data: () => ({
    statuses: PLANNING_STATUSES,
    editModal: {
      item: null,
      show: false
    },
    permissions: permissions
  }),

  computed: {
    ...mapGetters([
      'timetables',
      'user'
    ])
  },

  methods: {
    openEdit (item) {
      this.editModal.item = item
      this.editModal.show = true
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
