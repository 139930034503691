<template lang="pug" functional>
  v-card
    .planning-table__empty
      img.planning-table__empty-icon(src="@/assets/svg/pig.svg")
      h1.planning-table__empty-title {{ 'messages.empty_table' | translate }}
      p.planning-table__empty-text {{ 'messages.info_planning' | translate }}

</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
  .planning-table__empty {
    height: 38.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-icon {
      width: 15rem;
      height: 15rem;
      opacity: .4;
      margin-bottom: 2rem;
    }

    &-title {
      font-size: 2.2rem;
      text-align: center;
      margin-bottom: 1.5rem;
    }

    &-text {
      max-width: 40rem;
      font-size: 1.6rem;
      text-align: center;
      line-height: 1.4;
    }
  }
</style>
