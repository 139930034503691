import { render, staticRenderFns } from "./PlanningTableEmpty.vue?vue&type=template&id=a80cc70c&scoped=true&lang=pug&functional=true"
import script from "./PlanningTableEmpty.vue?vue&type=script&lang=js"
export * from "./PlanningTableEmpty.vue?vue&type=script&lang=js"
import style0 from "./PlanningTableEmpty.vue?vue&type=style&index=0&id=a80cc70c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "a80cc70c",
  null
  
)

export default component.exports